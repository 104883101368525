<template>
  <div class="footer-action">
    <b-col v-if="backAction">
      <b-button class="btn-cancel btn-width" @click="$emit('routePath')"
        >Cancel</b-button
      >
    </b-col>

    <b-col v-else>
      <router-link v-if="routePath" :to="routePath">
        <b-button variant="outline-primary-color" class="btn-width"
          >Cancel</b-button
        >
      </router-link>

      <b-button
        class="main-color btn-save btn-width ml-3"
        v-if="textCustom"
        @click="$emit('customAction')"
        >{{ textCustom }}</b-button
      >
    </b-col>

    <b-col class="text-right" v-if="!hideSubmit">
      <b-button class="btn-primary-color btn-width" @click="$emit('submit')">
        Save
      </b-button>
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    hideSubmit: {
      required: false,
      default: false,
      type: Boolean,
    },
    backAction: {
      required: false,
      type: Boolean,
      default: false,
    },
    routePath: {
      required: false,
    },
    textCustom: {
      required: false,
      type: String,
    },
  },
  created() {},
  destroyed() {},
  methods: {},
};
</script>

<style>
@media (min-width: 200px) and (max-width: 414px) {
  .footer-action {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.footer-action {
  box-shadow: 0px 5px 0px black, 0px 5px 10px black;

  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  display: flex;
  flex-direction: row;
}

.btn-width {
  width: 125px !important;
}
</style>
