<template>
  <div>
    <InputText
      v-model="search"
      type="text"
      faIcon="search"
      className="bg-second w-30"
      placeholder="ค้นหาบาร์โค้ด, ชื่อสินค้า, Product code"
      @onClickIcon="searchProduct"
      @onEnter="searchProduct"
    />
    <ModalProductList
      :isMobile="isMobile"
      ref="modalProductList"
      @addCredit="addCredit"
      :sourceBranchId="form.source_branch_id"
      @setSelectProduct="addProduct"
    />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalProductList from "./ModalProductList";
export default {
  components: { ModalProductList, InputText },
  props: {
    form: {
      required: true,
    },
  },
  data() {
    return { search: "", isMobile: false };
  },
  methods: {
    addCredit() {},
    addProduct(val) {
      this.search = "";
      this.$emit("setProduct", val);
    },
    async searchProduct() {
      if (this.form.source_branch_id == 0)
        return this.errorAlert("กรุณาเลือกสาขาต้นทาง ก่อนเพิ่มสินค้า", "");
      if (this.search.length) {
        await this.$store.dispatch("setMainLoading", true);
        const list = await this.findProductSerial();
        if (list.length == 1) {
          let payload = {
            product_image: list[0].product_image,
            barcode: list[0].barcode,
            product_name: list[0].name,
            quantity: 1,
            serial: list[0].serial_name || "",
            is_serial: list[0].is_serial,
            serial_id: "",
            product_id: list[0].product_id,
          };
          this.addProduct(payload);
          await this.$store.dispatch("setMainLoading", false);
        } else {
          this.$refs.modalProductList.show({}, this.search);
        }
      } else {
        this.$refs.modalProductList.show({}, this.search);
      }
    },
    async findProductSerial() {
      // this.isBusy = true;
      let body = {
        articleType: "",
        ah2_id: null,
        ah3_id: null,
        ah4_id: null,
        search: this.search,
        skip: (1 - 1) * 10,
        take: 10,
        spa: false,
        booth: true,
        branch_id: this.form.source_branch_id,
      };
      const res = await this.axios.post(
        `${this.$baseUrl}/product/search_product_serial`,
        body
      );
      return res.detail;
    },
  },
};
</script>

<style>
.w-30 {
  width: 30%;
}
</style>
